export default class CreationSAV {
    constructor($view) {
        this.view = $view;
        this.bind($view);
    }
    bind() {
        $(document).ready(function () {

            $('#BoutonValiderCreateSAVModal').click($.fn.CreationSAV.validerCreationSAV);

            $('#ListTypeOrigineSAV').change($.fn.CreationSAV.OrigineChange);

            $("#ComplementOriForm").attr('style', 'display:none');
            $("#ComplementOri").val("");
            $("#ComplementOri").removeAttr("data-val-required");

            var datepickerDebut = $("#DateDebut").data("kendoDatePicker");
            datepickerDebut.bind("change", function () {
                var currentDateDebut = this.value();
                if (currentDateDebut) {
                    var dpLivraison = $("#DateDeLivraison").data("kendoDatePicker");
                    dpLivraison.max(currentDateDebut);
                }
                $("#WeekDateDebut").text($.fn.CreationSAV.getNumWeek(this.value()));
                $("#form-projet-SaveCreationSAV").data("kendoValidator").validate();
            });

            var datepickerLivraison = $("#DateDeLivraison").data("kendoDatePicker");
            datepickerLivraison.bind("change", function () {
                var currentDateLivraison = this.value();
                if (currentDateLivraison) {
                    $("#WeekDateDeLivraison").text($.fn.CreationSAV.getNumWeek(currentDateLivraison));
                    var dpDebut = $("#DateDebut").data("kendoDatePicker");
                    var dateDebut = dpDebut.value();
                    dpDebut.min(currentDateLivraison);
                    if (dateDebut < currentDateLivraison) {
                        dpDebut.value(currentDateLivraison);
                        dpDebut.trigger("change");
                    }
                }
                else {
                    $("#WeekDateDeLivraison").text('');
                }
                $("#form-projet-SaveCreationSAV").data("kendoValidator").validate();
            });

            $("#WeekDateDebut").text($.fn.CreationSAV.getNumWeek(datepickerDebut.value()));
            $("#WeekDateDeLivraison").text($.fn.CreationSAV.getNumWeek(datepickerLivraison.value()));

            let formProjet = $("#form-projet-SaveCreationSAV");
            kendo.init(formProjet);
            formProjet.kendoValidator({
                validate: function (ev) {
                    if (!ev.valid) {
                        $(".k-invalid:first").focus();
                    }
                },
                rules: {
                    issmallerorequaldatethan: function (input) {
                        if (input.is("[data-issmallerorequaldatethan-msg]") && input.val() != "") {
                            var date = kendo.parseDate(input.val()),
                                otherDate = kendo.parseDate($("[name='" + input.data("issmallerorequaldatethanField") + "']").val());
                            return otherDate == null || date.getTime() <= otherDate.getTime();
                        }

                        return true;
                    },
                    ispast: function (input) {
                        if (input.is("[data-ispast-msg]") && input.val() != "") {
                            var date = kendo.parseDate(input.val());
                            if (date instanceof Date) {
                                var now = new Date();
                                var today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
                                return date >= today;
                            }
                        }
                        return true;
                    }
                },
                messages: {
                    ispast: function (input) {
                        return $(input).data("ispast-msg");
                    },
                    issmallerorequaldatethan: function (input) {
                        return $(input).data("issmallerorequaldatethan-msg");
                    }
                }
            });
            formProjet.data("kendoValidator").validate();
        });

        $.fn.CreationSAV = {
            validerCreationSAV: function () {
                if($("#form-projet-SaveCreationSAV").data("kendoValidator").validate())
                {
                    $("#form-projet-SaveCreationSAV").submit();
                }
            },

            OrigineChange: function () {
                if (document.getElementById("ListTypeOrigineSAV").value === "Autre") {
                    $("#ComplementOriForm").removeAttr('style');
                    $("#ComplementOri").attr("data-val-required", ResourceCreationSav.ChampObligatoire);
                }
                else {
                    $("#ComplementOriForm").attr('style', 'display:none');
                    $("#ComplementOri").val("");
                    $("#ComplementOri").removeAttr("data-val-required");
                }
            },

            OnBeginCreationSAV: function () {
                Swal.showLoading();
            },
            OnSuccessCreationSAV: function (data) {
                Swal.close();
                if (data.successEdit == 1) {
                    if (data.IsCreation === true) {
                        toastr.success(ResourceCreationSav.ModificationsEffectuees);
                        if (data.isFromOutilGestion === true) {
                            $('#ModalCustom').modal('hide');
                            location.href = urlActionCreationSav.actionValiderFiche + "?Wipoz_ProjetID=" + data.Wipoz_ProjetID;
                        }
                        else if (data.url) {
                            location.assign(data.url);
                        }
                        else {
                            $.ajax({
                                url: urlActionCreationSav.actionGetModalPublication,
                                method: "POST",
                                async: false,
                                data: {
                                    Wipoz_ProjetID: data.Wipoz_ProjetID
                                },
                                success: function (html) {
                                    $("#ModalCustom .modal-dialog").addClass(html.stylelayout);
                                    $("#ModalCustom .modal-body").html(html.content);
                                    $("#ModalCustom .modal-header").hide();
                                    $("#ModalCustom .modal-footer").hide();
                                    window.Kernel.mountComponents($('#ModalCustom'));
                                    $("#ModalCustom").modal("show");
                                },
                                error: function () {
                                    $('#ModalCustom').modal('hide');
                                    toastr.error(ResourceCreationSav.ImpossiblePublierProjet);
                                }
                            });
                        }
                    }
                    else {
                        window.location.reload();
                        toastr.success(ResourceCreationSav.ModificationsEffectuees);
                    }
                }
                else if (data.successEdit == -1) {
                    $('#ModalCustom').modal('hide');
                    toastr.error(ResourceCreationSav.ImpossibleEnregistrerProjet);
                }
                else if (data.successEdit == -2) {
                    toastr.error(ResourceCreationSav.ErreurAccess);
                }
                else {
                    toastr.error(ResourceCreationSav.ErreurGlobal);
                }
            },

            OnFailureCreationSAV: function () {
                Swal.close();
                $('#ModalCustom').modal('hide');
                toastr.error(ResourceCreationSav.ErreurGlobal);
            },

            getNumWeek: function (dateInput) {
                var result;
                if (dateInput) {
                    var onejan = new Date(dateInput.getFullYear(), 0, 1);
                    var today = new Date(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate());
                    var dayOfYear = ((today - onejan + 86400000) / 86400000);
                    var weekNo = Math.ceil(dayOfYear / 7);
                    result = ResourceCreationSav.SemaineNumero + weekNo;
                }
                else {
                    result = "";
                }
                return result;
            }
        };
    };
}