export default class PlanningActions {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {
        var proprietaireSelected = [];
        var filtersSelected = [];
        var mlpSelected = [];
        var typeSelected = [];
        var partnerSelected = [];
        var ecart = 0;

        $.fn.Event = {
            addEvent: function () {
                var scheduler = $("#Scheduler").data("kendoScheduler");
                scheduler.addEvent({ start: moment().startOf('day').toDate(), end: moment().startOf('day').toDate()});
            },
            navigate: function (e) {
                $('.popover').popover('hide');

                if (e.action == "changeView" && !$.fn.Event.ongletDisponible(e.view)) {
                    if (e.view != "workWeek" && $.fn.Event.ongletDisponible("workWeek")) {
                        e.sender.view("workWeek")
                        return;
                    }
                    if (e.view != "week" && $.fn.Event.ongletDisponible("week")) {
                        e.sender.view("week")
                        return;
                    }
                    if (e.view != "month" && $.fn.Event.ongletDisponible("month")) {
                        e.sender.view("month")
                        return;
                    }
                }
            },
            ongletDisponible: function (name) {
                var disponible = false;
                $(".k-scheduler-views").find("li").each(function (i, e) {
                    if ($(e).data("name") == name) {
                        disponible = true;
                    }
                });
                return disponible;
            },
            schedulerEdit: function (e) {
                if (e.event.IsProjet == true) {
                    e.preventDefault();
                    return;
                }

                ecart = moment(e.event.end).diff(e.event.start, "minutes");

                if (!e.event.Id) {
                    if (ecart > 0) {
                        var start = e.event.start;
                        var end = e.event.end;
                        e.event.set("isAllDay", false);
                        e.event.set("end", end);
                        e.event.set("start", start);
                    }

                    if (e.event.ProprietaireID) {
                        e.event.set("Wipoz_ClientID", e.event.ProprietaireID);
                    }
                }

                $.fn.Event.borneDatePicker(e.event, "StartWithTime", "EndWithTime", "kendoDateTimePicker", "End", "kendoDatePicker");
                $.fn.Event.borneDatePicker(e.event, "Start", "End", "kendoDatePicker", "EndWithTime", "kendoDateTimePicker");
            },
            showMessageErreur: function (e, texte) {
                e.show();
                e.addClass("k-widget field-validation-error");
                e.html(texte);
            },
            hideMessageErreur: function (e) {
                e.hide();
                e.removeClass("k-widget field-validation-error");
                e.html("");
            },
            borneDatePicker: function (event, start, end, type, endOther, typeOther) {
                $("#" + end).data(type).min(event.start);
                $("#" + endOther).data(typeOther).min(event.start);

                $("#" + start).on("change", function (e) {
                    var startPicker = $("#" + start).data(type);
                    var startDate = kendo.parseDate(startPicker.element.val());

                    var errorMessage = $("#" + start).parent().parent().parent().find(".k-invalid-msg");

                    if (startDate == null) {
                        $("#" + start).parent().addClass("k-invalid");
                        $.fn.Event.showMessageErreur(errorMessage, EventResource.DateDebutVide);
                        startPicker.value("");
                        return;
                    }

                    $("#" + start).parent().removeClass("k-invalid");
                    $.fn.Event.hideMessageErreur(errorMessage);

                    var endPicker = $("#" + end).data(type);
                    var dateBorne = moment(startDate).add(ecart, 'minutes').toDate();
                    endPicker.min(startDate);
                    endPicker.value(dateBorne);

                    var endPickerOther = $("#" + endOther).data(typeOther);
                    endPickerOther.min(startDate);
                    endPickerOther.value(dateBorne);

                    event.set("end", dateBorne);
                });

                $("#" + end).on("change", function (c) {
                    var endPicker = $("#" + end).data(type);
                    var endDate = kendo.parseDate(endPicker.element.val());
                    var errorMessage = $("#" + end).parent().parent().parent().find(".k-invalid-msg");
                    if (endDate == null) {
                        $("#" + end).parent().addClass("k-invalid");
                        $.fn.Event.showMessageErreur(errorMessage, EventResource.DateFinVide);
                        endPicker.value("");
                        return;
                    }

                    if (endDate < endPicker.min()) {
                        $("#" + end).parent().addClass("k-invalid");
                        $.fn.Event.showMessageErreur(errorMessage, EventResource.ErrorChronologie);
                        endPicker.value("");
                        return;
                    }

                    $("#" + end).parent().removeClass("k-invalid");
                    $.fn.Event.hideMessageErreur(errorMessage);

                    var startPicker = $("#" + start).data(type)
                    ecart = moment(endDate).diff(startPicker.value(), "minutes");
                });
            },
            schedulerError: function (e) {
                if (e.xhr.responseJSON
                    && e.xhr.responseJSON.message) {
                    toastr.error(e.xhr.responseJSON.message);
                }
                else {
                    toastr.error(EventResource.ErrorTraitement);
                }
            },
            schedulerResizeStart: function (e) {
                if (e.event.IsProjet == true) {
                    e.preventDefault();
                }
            },
            schedulerMoveStart: function (e) {
                if (e.event.IsProjet == true) {
                    e.preventDefault();
                }
            },
            schedulerDataBound: function () {
                var view = this.view();
                // Liste des évènements à afficher
                var events = this.dataSource.view();
                for (let event of events) {
                    // Get the event element.
                    let eventElement = view.element.find("[data-uid=" + event.uid + "]");
                    // Set the background of the element.
                    eventElement.addClass(event.css);
                    if (event.IsProjet == true) {
                        // On enlève la possibilité de supprimer un projet
                        eventElement.find(".k-event-delete").remove();
                        // On enlève la possibilité de resiez un projet
                        eventElement.find(".k-resize-handle").remove();
                    }
                }

                var onglet = $(".k-scheduler-views .k-state-selected").data("name");
                if (onglet == "workWeek" || onglet == "week") {
                    var startDate = $('.k-lg-date-format').text().split(" - ")[0];
                }

                if (onglet == "workWeek" || onglet == "week") {
                    //Add the week number on the header
                    var week = moment(startDate, "L").week();

                    if ($('.k-lg-date-format span').length) {
                        $('.k-lg-date-format span').text(" " + kendo.format(EventResource.Semaine, week));
                        $('.k-sm-date-format span').text(" " + kendo.format(EventResource.S, week));
                    }
                    else {
                        $('.k-lg-date-format').append("<span> " + kendo.format(EventResource.Semaine, week) + "</span>");
                        $('.k-sm-date-format').append("<span> " + kendo.format(EventResource.S, week) + "</span>");
                    }
                }

                //Ajouter les numeros de semaine dans le calendrier dans la barre du haut
                var schedDateLink = $('ul.k-scheduler-navigation').find('.k-nav-current');
                schedDateLink.on('click', function () {
                    setTimeout(function () {
                        var schedCalendar = $('.k-scheduler-calendar.k-widget.k-calendar').data('kendoCalendar');
                        schedCalendar.setOptions({ weekNumber: true });
                    }, 100);
                });

                //Activate les popovers
                $('[data-toggle="popover"]').popover({
                    html: true,
                    trigger: "click",
                    template: '<div class="popover card" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'

                })
                    .on('show.bs.popover', function () {
                        $('.popover').popover('hide');
                    })
                    .on('shown.bs.popover', function () {
                        var url = $(this).data('url');
                        var popoverID = "#" + $(this).attr("aria-describedby");
                        var contentEl = $(popoverID).children(".popover-body");

                        // Show spinner while waiting for data to be fetched
                        contentEl.html("<i class='fa fa-spinner fa-pulse fa-2x fa-fw'></i>");

                        $.get(url, function (data) {
                            contentEl.html(data);
                            window.Kernel.mountComponents(contentEl);

                        });
                    });

                document.onclick = function (event) {
                    //On cherche si l'event-target est scheduler-container ou s'il en a le parent.
                    //Sinon on cache les elements popover
                    var schedulerParent = $(event.target).parents(".scheduler-container");
                    if (schedulerParent.length === 0 && !$(event.target).hasClass("scheduler-container")) {
                        $(".popover").popover('hide');
                        $(".popover-body").removeAttr("aria-describedby");
                    }
                }
            },
            addFilter: function (type, param) {
                var paramFilter = type + '-' + param;
                $('#' + paramFilter).addClass("filter-selected");
                switch (type) {
                    case 'proprietaire':
                        if (proprietaireSelected.indexOf(param) === -1) {
                            proprietaireSelected.push(param);
                            filtersSelected.push(paramFilter);
                        }
                        else {
                            proprietaireSelected.splice($.inArray(param, proprietaireSelected), 1);
                            filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                            $('#' + paramFilter).removeClass("filter-selected");
                        }
                        break;
                    case 'mlp':
                        if (mlpSelected.indexOf(param) === -1) {
                            mlpSelected.push(param);
                            filtersSelected.push(paramFilter);
                        }
                        else {
                            mlpSelected.splice($.inArray(param, mlpSelected), 1);
                            filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                            $('#' + paramFilter).removeClass("filter-selected");
                        }
                        break;
                    case 'type':
                        if (typeSelected.indexOf(param) === -1) {
                            typeSelected.push(param);
                            filtersSelected.push(paramFilter);
                        }
                        else {
                            typeSelected.splice($.inArray(param, typeSelected), 1);
                            filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                            $('#' + paramFilter).removeClass("filter-selected");
                        }
                        break;
                    case 'partner':
                        if (partnerSelected.indexOf(param) === -1) {
                            partnerSelected.push(param);
                            filtersSelected.push(paramFilter);
                        }
                        else {
                            partnerSelected.splice($.inArray(param, partnerSelected), 1);
                            filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                            $('#' + paramFilter).removeClass("filter-selected");
                        }
                        break;
                }
                $.fn.Event.UpdatePlanning();
                $.fn.Event.UpdateFilters();
            },
            removeFilter: function (type, param) {
                var paramFilter = type + '-' + param;
                $('#' + paramFilter).removeClass("filter-selected");
                switch (type) {
                    case 'proprietaire':
                        if (proprietaireSelected.indexOf(param) !== -1) {
                            proprietaireSelected.splice($.inArray(param, proprietaireSelected), 1);
                        }
                        filtersSelected.indexOf(paramFilter) === -1 ? console.log("This item doesnt exist") : filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                        break;
                    case 'mlp':
                        if (mlpSelected.indexOf(param) !== -1) {
                            mlpSelected.splice($.inArray(param, mlpSelected), 1);
                        }
                        filtersSelected.indexOf(paramFilter) === -1 ? console.log("This item doesnt exist") : filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                        break;
                    case 'type':
                        if (typeSelected.indexOf(param) !== -1) {
                            typeSelected.splice($.inArray(param, typeSelected), 1);
                        }
                        filtersSelected.indexOf(paramFilter) === -1 ? console.log("This item doesnt exist") : filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1);
                        break;
                    case 'partner':
                        if (partnerSelected.indexOf(param) !== -1) {
                            partnerSelected.splice($.inArray(param, partnerSelected), 1);
                        }
                        filtersSelected.indexOf(paramFilter) === -1 ? console.log("This item doesnt exist") : filtersSelected.splice($.inArray(paramFilter, filtersSelected), 1)
                        break;
                }
                $.fn.Event.UpdatePlanning();
                $.fn.Event.UpdateFilters();
            },
            UpdatePlanning: function (e) {
                var filterWrapper = {
                    logic: "and",
                    filters: []
                }
                //Filtre par proprietaire
                var filterByProprietaire = {
                    logic: "or",
                    filters: $.map(proprietaireSelected, function (value) {
                        return {
                            operator: "eq",
                            field: "ProprietaireID",
                            value: value
                        };
                    })
                };
                if (filterByProprietaire.filters.length > 0) {
                    filterWrapper.filters.push(filterByProprietaire);
                }

                var filterByType = {
                    logic: "or",
                    filters: $.map(typeSelected, function (value) {
                        return {
                            operator: "eq",
                            field: "Wipoz_TypeProjetID",
                            value: value
                        };
                    })
                };
                if (filterByType.filters.length > 0) {
                    filterWrapper.filters.push(filterByType);
                }

                var filterByPartner = {
                    logic: "or",
                    filters: $.map(partnerSelected, function (value) {
                        return {
                            operator: "eq",
                            field: "PartnerId",
                            value: value
                        };
                    })
                };
                if (filterByPartner.filters.length > 0) {
                    filterWrapper.filters.push(filterByPartner);
                }

                var filterByMLP = {
                    logic: "or",
                    filters: $.map(mlpSelected, function (value) {
                        return {
                            operator: "eq",
                            field: "IsServiceWipoz",
                            value: value
                        };
                    })
                };
                if (filterByMLP.filters.length > 0) {
                    filterWrapper.filters.push(filterByMLP);
                }

                //Gestion des filtres Etat Avancement
                var checked = $.map($(".etatAvancement:checked"), function (checkbox) {
                    return parseInt($(checkbox).val());
                });
                var filterEtatAvancement = {
                    logic: "or",
                    filters: $.map(checked, function (value) {
                        return {
                            operator: "eq",
                            field: "EtatAvancement",
                            value: value
                        };
                    })
                }

                if (filterEtatAvancement.filters.length > 0) {
                    // Les évènements non projet doivent apparaitre
                    filterEtatAvancement.filters.push({
                        field: 'IsProjet',
                        value: 0,
                        operator: 'eq'
                    })
                    filterWrapper.filters.push(filterEtatAvancement);
                }

                var scheduler = $("#Scheduler").data("kendoScheduler");
                scheduler.dataSource.filter(filterWrapper);
            },
            UpdateFilters: function () {
                $(".filter-array").filter(function () {
                    $(this).hide();
                    for (var i = 0; i < filtersSelected.length; i++) {
                        if ($(this).data('filter') == filtersSelected[i]) {
                            $(this).show();
                        }
                    }
                });
            },
            schedulerRequestStart: function (e) {
                Swal.showLoading();
                if (e.type == "create" || e.type == "update") {
                    $(".k-overlay").hide();
                    $(".k-window").css("z-index", "10");
                    $(".swal2-container").css("z-index", "2000");
                }
            },
            schedulerRequestEnd: function (e) {
                Swal.close();
            },
        }
    };
}