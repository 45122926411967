export default class DocumentManagement {
    constructor($view) {
        this.view = $view;
        this.bind();
    }
    bind() {

        $(document).ready(function () {
            if ($(this.view).data("controldocument") === "True") {
                $.fn.DocumentManagement.checkedMustReadDocument();
            }
        });

        $.fn.DocumentManagement = {
            DocPoseurUpload: function (e) {
                e.data = {
                    Id: $('#Wipoz_ProjetID').val(),
                    typeDocumentId: $("#Wipoz_TypeDocumentID").val(),
                    libelle: $("#DocumentLibelle").val(),
                    commentaire: $("#Commentaire").val(),
                    envoimail: true
                };
            },

            OnBeginUploadDocument: function () {
                Swal.fire({
                    title: ResourceAjoutDocument.LoadingMessage,
                    allowOutsideClick: () => !Swal.isLoading()
                });
                Swal.showLoading();
            },
            UpdateDocumentList: function (data) {
                if (data.success == -1) {
                    Swal.close();
                    Swal.fire(ResourceAjoutDocument.TitleAddDocument, data.message, "error");
                }
                else {
                    if ($("#EtapeAjoutFichier").val() === "0") {
                        $.ajax({
                            url: urlActionAjoutDocument.actionGetDocumentProjet,
                            method: "GET",
                            async: false, // Mode synchrone
                            data: { Wipoz_ProjetID: $("#Wipoz_ProjetID").val() },
                            success: function (datas) {
                                Swal.close();
                                $(".js-documents-projet").html(datas);
                                $("#Modal").modal("hide");
                            },
                            error: function () {
                                Swal.close();
                            }
                        });
                    }
                    else {
                        window.location.reload();
                    }
                }
            },

            AddDocumentFailure: function () {
                Swal.close();
                Swal.fire(ResourceAjoutDocument.TitleAddDocument, ResourceAjoutDocument.DemandeAjoutDocumentErreur, "error");
            },

            GenererDossierPose: function (wipoz_ProjetID, wipoz_TypeDocumentID, isRegenerated, wipoz_EtatAvancementID) {
                var texte = DocumentNumeriqueRsxFiche.GenererDossierDePose_Text1;

                if (isRegenerated && wipoz_EtatAvancementID === "1") {
                    texte = DocumentNumeriqueRsxFiche.GenererDossierDePose_Text3;
                }
                else if (isRegenerated) {
                    texte = DocumentNumeriqueRsxFiche.GenererDossierDePose_Text2;
                }

                Swal.fire({
                    title: DocumentNumeriqueRsxFiche.GenererDossierDePose_Titre,
                    text: texte,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#2ABFBA',
                    cancelButtonColor: '#E0E0E0',
                    confirmButtonText: ResourceFiche.Oui,
                    showLoaderOnConfirm: () => !Swal.isLoading(),
                    cancelButtonText: ResourceFiche.Non,
                    reverseButtons: true,
                    preConfirm: (result) => {
                        if (result) {
                            Swal.fire({
                                title: DocumentNumeriqueRsxFiche.CommentaireGenerationDocumentNumerique,
                                allowOutsideClick: () => !Swal.isLoading()
                            });
                            Swal.showLoading();

                            $.ajax({
                                url: urlActionFicheAlertsActions.actionGenererDocument,
                                type: "POST",
                                async: false,
                                data: {
                                    wipoz_ProjetID: wipoz_ProjetID,
                                    wipoz_TypeDocumentID: wipoz_TypeDocumentID
                                },
                                success: function (data) {
                                    Swal.close();
                                    if (data.success === 0) {
                                        toastr.error(data.message);
                                    }
                                    else {
                                        window.location.reload();
                                        toastr.success(DocumentNumeriqueRsxFiche.SuccessMsg_DossierDePose);
                                    }
                                },
                                error: function () {
                                    Swal.close();
                                    Swal.fire(DocumentNumeriqueRsxFiche.GenererDossierDePose_Titre,
                                        DocumentNumeriqueRsxFiche.ErrorMsg_GenererDocumentNumerique,
                                        "error");
                                }
                            });
                        }
                    }
                })
            },

            onReadDocument: function (e) {
                if ($(e).data("readRequired") === "True") {
                    $(e).removeAttr("title");
                    $(e).find("i.fa-eye").hide();
                    $(e).find("i.fa-check").removeClass("d-none");
                    $(e).find("i.fa-exclamation-circle").addClass("d-none");
                    $(e).data("read", true);

                    $.fn.DocumentManagement.checkedMustReadDocument();
                }
            },

            checkedMustReadDocument: function () {
                var allRead = true;

                $(".btn-lienDocument a").each(function (i, e) {
                    if ($(e).data("readRequired") === "True"
                        && !$(e).data("read")) {
                        allRead = false;
                    }
                });

                if (allRead) {
                    $("button#BoutonEngagement").prop("disabled", false);
                    $("button#BoutonEngagement").removeClass("btn-disabled");
                    $("button#BoutonEngagement").addClass($("button#BoutonEngagement").data("classread"));
                    $("button#BoutonEngagement").removeAttr("disabled");
                    $("#BoutonEngagement").removeAttr("title");
                    $("a#BoutonRefus").prop("disabled", false);
                    $("a#BoutonRefus").removeClass("btn-disabled");
                    $("a#BoutonRefus").addClass($("a#BoutonRefus").data("classread"));
                    $("a#BoutonRefus").removeAttr("disabled");
                    $("#BoutonRefus").removeAttr("title");
                    $("#js-CompletedRead").hide();
                }
                else {

                }
            }
        }
    }
}